<template>
    <div class="header">
        <div class="wrap flex-box flex-align-center">
            <router-link to="{name:home}">
                <img src="@/assets/logo.png" width="42" height="44" alt="">
            </router-link>
            <div class="nav flex-box m0">
                <a v-for="item in navPath" :target="item.pathName=='worksList'?'_blank':'_self'" :key="item.pathName" :href="'/'+item.pathName" :class="curPath==item.pathName?'act':''" class="nav-item cfff">{{ item.text }}</a>
            </div>
            <div class="login flex-shrink">
                <div class="hasLogin flex-box"  v-if="isLogin">
                    <!-- tab=3用于在个人中心页获取tab值后一进入个人中心页就切换到消息中心 -->
                    <router-link :to="{name:'personalCenter',params:{tab:3}}">
                        <div class="tip">
                            <img src="@/assets/tip.png" width="25" height="25" alt="">
                            <span class="msgNum" v-if="noticeLen">{{ noticeLen }}</span>
                        </div>
                    </router-link>
                    <a-dropdown>
                        <div class="flex-box flex-align-center">
                            <a-avatar :src="userInfo.headPicUrl" :size="25" icon="user" />
                            <span class="ml15 cfff single-row-ellipsis user-name ">{{ userInfo.nickName }}</span>
                        </div>
                        <a-menu slot="overlay" class="mt15">
                            <a-menu-item>
                                <router-link :to="{name:'personalCenter',params:{tab:1}}">个人中心</router-link>
                            </a-menu-item>
                            <a-menu-item>
                                <span @click="exit">退出登录</span>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
                <div v-else class="notLogin flex-box flex-align-center" @click="openLoginModal">
                    <img width="26" height="26" src="@/assets/user.png" />
                    <span class="ml15 cfff">登录</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getNoticeList } from '@/service/personalCenter';
import { logout } from '@/service/login';
import { toQrLogin } from '@/utils/fn';
import Store from '@/store'
import { mapState } from 'vuex'
export default {
    name: 'Header',
    data() {
        return {
            navPath: [
                {
                    'pathName': 'home',
                    'text': '首页'
                },
                {
                    'pathName': 'worksList',
                    'text': '作品'
                }
            ],
            curPath: '',
        }
    },
    computed:{
        ...mapState('user', ['userInfo', 'isLogin', 'noticeLen']),
    },
    watch:{
        $route(to) {
            this.curPath = to.name;
            this.getNoticeList();
        }
    },
    mounted() {
        this.curPath = this.$route.name;
    },
    methods: {
        exit() {
            logout().then(({ data }) => {
                if (+data.errcode === 0) {
                    this.$message.success(data.errmsg);
                    const fullPath = window.location.href;
                    this.$router.push(fullPath);
                    Store.commit('user/setUserInfo', {});
                    Store.commit('user/removeUserInfo', true);
                } else {
                    this.$message.error(data.errmsg);
                }
            })
        },
        openLoginModal() {
            toQrLogin();
        },
        getNoticeList() {
            getNoticeList().then(({ data }) => {
                if (+data.errcode === 0) {
                    Store.commit('user/noticeLen',data.data.list.filter(item=>+item.isRead===0).length);
                } else {
                    console.log(data.errmsg)
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.header{
    background: #4e4d52;
    color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
}

.nav{
    padding-left: 72px;
}
.nav .nav-item{
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-size: 18px;
}
.nav .nav-item.act{
    background: #0fccd2;
    position: relative;
}
.nav .nav-item.act::before,
.nav .nav-item.act::after
{
    position: absolute;
    top: 50%;
    margin-top: -10px;
    content:'';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.nav .nav-item.act::before{
    left: 0;
    border-left: 10px solid #4e4d52;
}
.nav .nav-item.act::after{
    right: 0;
    border-right: 10px solid #4e4d52;
}
.login{
    font-size: 18px;
    margin-left: auto;
    cursor: pointer;
}
.tip{
    margin: 0 28px 0 0;
    position: relative;
}
.msgNum{
    width: 16px;
    height: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    border-radius: 50%;
    position: absolute;
    right: -9px;
    top: -4px;
    background: #ff0000;
}
.ant-dropdown,.ant-dropdown::before{
    min-width: 98px !important;
    width: 98px !important;
}
.ant-dropdown-menu{
    border-radius: 9px !important;
    width: 98px !important;
    padding: 9px 0;
}
.ant-dropdown-menu-item{
    font-size: 16px;
    border-radius: 9px;
    text-align: center;
    padding: 8px 5px;
}
.user-name {
    width: 120px;
}
</style>>