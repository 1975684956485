import { requestData } from '@/utils/request';

// 获取赛事时间
export const getGameTime = (data) => {
    return requestData({
      url: `/v1/get.game.time`,
      data,
    })
}

// 获取作品类型
export const getWorksType = (data) => {
  return requestData({
    url: `/v1/get.works.type`,
    data,
  })
}

// 投票
export const worksVote = (data) => {
  return requestData({
    type:'POST',
    url: `/v1/doc.vote`,
    data,
  })
}

// 获取首页轮播图和新闻
export const getNewsList = (data) => {
  return requestData({
    url: `v1/home.news.list`,
    data,
  })
}

// 获取作品列表
export const getWorkList = (data) => {
  return requestData({
    url: `/v1/home.doc.list`,
    data,
  })
}

// 相关推荐数据
export const getRecommendList = (data) => {
  return requestData({
    url: `/v1/get.recommend`,
    data,
  })
}

// 获取文章详情
export const getArticleDetail = (data) => {
  return requestData({
    url: `/v1/article.detail`,
    data,
  })
}

// 获取标签
export const getTags = (data) => {
  return requestData({
    url: `/v1/get.tag.list`,
    data,
  })
}
// 获取标签
export const getMiniappUrlLink = (data) => {
  return requestData({
    url: `/v1/wechat.app.urllink`,
    data,
  })
}

