import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Store from '@/store'
import { getUserInfo } from '@/service/login';
import { toQrLogin } from '@/utils/fn';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: ()=>import('../views/UploadWorks')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/UserAgreement')
  },
  {
    path: '/declare',
    name: 'declare',
    component: () => import('../views/MatchDeclare')
  },
  {
    path: '/match',
    name: 'match',
    component: () => import('../views/MatchDetail')
  },

  {
    path: '/worksList',
    name: 'worksList',
    component: () => import('../views/WorksList')
  },
  {
    path: '/articleDetail/:docId',
    name: 'articleDetail',
    component: ()=>import('../views/ArticleDetail')
  },
  {
    path: '/personalCenter/:tab',
    name: 'personalCenter',
    component: ()=>import('../views/PersonalCenter')
  },
  {
    path: '/worksDetail',
    name: 'worksDetail',
    component: ()=>import('../views/WorksDetail')
  },
  {
    path: '/awards',
    name: 'awards',
    component:()=>import('../views/Awards.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../views/Login')
  },
  {
    path: '*',
    name: 'noFound',
    title: '未找到',
    redirect: {
      name: 'home'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const data = await getUserInfo();
  // 未登录
  if (+data.data.errcode === 401) {
    Store.commit('user/setUserInfo', {});
    if (to.name === 'personalCenter') {
      toQrLogin();
    } else {
      next();
    }
  }

  // 已登录
  const res = data.data;
  if (+res.errcode === 0) {
    Store.commit('user/setUserInfo', res.data);
    next();
  }
})

router.afterEach((to, from, next) => {
  if (to.name != 'worksList') {
    window.scrollTo(0,0)
  }
})

export default router
