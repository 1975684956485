<template>
    <div class="header-loop">
        <a-carousel class="header-carousel" autoplay>
            <div :key="index" v-for="(item,index) in bannerList"><a :href="item.link" target="_blank"><img :src="item.picUrl" :alt="item.title"></a></div>
        </a-carousel>
    </div>
</template>
<script>
import { getNewsList } from '@/service/home';
export default {
    name:'HeaderLoop',
    data() {
        return {
          bannerList:[]
        }
    },
    created() {
      this.getNewsList();
    },
    methods: {
      getNewsList() {
        getNewsList().then(({ data }) => {
          if (+data.errcode === 0) {
            const { bannerList, rotateList, newsList } = data.data;
            this.bannerList = bannerList;
            this.$emit('otherData', { rotateList, newsList })
          } else {
            console.log(data.errmsg)
          }
        })
      },
    }
}
</script>
<style scoped>
.header-carousel.ant-carousel >>> .slick-slide {
  height: 730px;
  background: #364d79;
  overflow: hidden;
} 

/deep/ .header-carousel.ant-carousel .slick-dots li button{
  width: 100px;
  height: 10px;
  border-radius: 5px;
  background: #545454;
  margin: 0 24px;
}
/deep/ .header-carousel.ant-carousel .slick-dots li.slick-active button{
    background: #6ddadf;
}
/deep/ .header-carousel.ant-carousel .slick-dots-bottom{
  bottom: 58px;
}

/deep/ .ant-carousel .slick-slide img{
  margin: 0 auto;
}
</style>