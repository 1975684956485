import { requestData } from '@/utils/request';
export const getPersonalCenter = (data) => {
  return requestData({
    url: `/v1/user.info.get`,
    data,
  })
}
export const getModifyData = (data) => {
  return requestData({
    type: 'post',
    url: `/v1/user.info.set`,
    data,
  })
}
export const getWorksList = (data) => {
  return requestData({
    url: `/v1/user.doc.list`,
    data,
  })
}

export const DeleteWorks = (data) => {
  return requestData({
    type: 'post',
    url: `/v1/user.doc.del`,
    data,
  })
}

export const getNoticeList = (data) => {
  return requestData({
    url: `/v1/user.notice.list`,
    data,
  })
}

export const setNoticeReadList = (data) => {
  return requestData({
    type: 'post',
    url: `/v1/user.notice.read`,
    data,
  })
}

export const setNoticeRead = (data) => {
  return requestData({
    url: `/v1/user.notice.read`,
    data,
  })
}
