export const partnersData = [
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic1.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic2.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic3.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic4.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic5.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic6.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic7.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic8.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic9.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic10.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic11.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic12.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic13.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic14.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic15.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic16.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic17.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic18.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic19.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic20.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic21.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic22.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic23.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic24.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic25.jpg',
    },
    {
        img: 'https://www.c3online.com.cn/img/assets/images/logos/pic26.jpg',
    }
]