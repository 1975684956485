<template>
    <div class="footer">
        <div class="wrap clearfix">
            <div class="fl footer-text f16 text-left">
                <p class="m0">上海汉威信恒展览有限公司   备案号：沪ICP备16012922号-13</p>
                <p class="m0">联系邮箱：cj.connect@howellexpo.net </p>
            </div>
            <div class="fr footer-logo">
                <img src="https://www.c3online.com.cn/img/assets/images/footer-logo.png" alt="" width="230">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Footer'
}
</script>
<style lang="less" scoped>
.footer{
    background: #4E4D52;
    padding: 24px 0;
    color: #fff;
}
.footer-text{
    line-height: 26px;
    padding-left: 24px;
}
.footer-logo{
    margin: 5px 4px 0 0;
}
</style>