<template>
  <div id="app">
    <CommonHeader v-if="!isPreview" />
    <!-- <div>{{time|dayjs}}</div> -->
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
    <CommonFooter v-if="isLoad && !isPreview" />
  </div>
</template>
<script>
import CommonHeader from '@/components/Header.vue'
import CommonFooter from '@/components/Footer.vue'
export default {
  components: {
    CommonHeader,
    CommonFooter,
  },
  data() {
    return {
      time: new Date(),
      isLoad: false,
      isPreview:0
    }
  },
  mounted() {
    this.isPreview = this.GetQueryString('isPreview')
    setTimeout(() => {
      this.isLoad = true
    }, 500)
  },
  methods: {
    GetQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
  },
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f6f5fb;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
