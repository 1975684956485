import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Antd from "ant-design-vue";
import { VueAxios } from './utils/request'
import './core/lazy_use'  // use lazy load components
// import './permission'
import './utils/filter' // global filter
import './global.less'

// Vue.use(Antd)
Vue.use(VueAxios)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
