import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        userInfo: {},
        isLogin:false,
        noticeLen:0,
    },
    getters: {
        userInfo: (state) => state.userInfo,
        isLogin: (state) => state.isLogin,
        noticeLen: (state) => state.noticeLen,
    },
    mutations: {
        setUserInfo(state, user) {
            Vue.set(state, 'userInfo', user)
            state.isLogin=!!(Object.keys(state.userInfo).length)
        },
        removeUserInfo(state,isRemove) {
            state.isLogin = isRemove;
        },
        noticeLen(state, noticeLen) {
            state.noticeLen = noticeLen;
        }
    },
};
  