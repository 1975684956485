<template>
  <div class="home f18 common-btm">
    <!-- 头部banner轮播 -->
    <HeaderLoop @otherData="getOtherData" />
    <!-- 轮播图+新闻列表 -->
    <div class="wrap clearfix news">
      <a-carousel class="news-carousel fl" autoplay v-if="loopList.length">
        <div class="news-box-l" v-for="(item, index) in loopList" :key="index">
          <a :href="item.link" target="_blank">
            <img :src="item.picUrl" :alt="item.title" />
            <span class="news-title-l single-row-ellipsis">{{ item.title }}</span>
          </a>
        </div>
      </a-carousel>
      <div class="news-list-r fr m0">
        <!-- <a class="news-list-item clearfix" v-for="item in newsList" :href="{name:'articleDetail',params:{docId:item.articleId}}" :key="item.articleId"> -->
        <a
          class="news-list-item clearfix"
          v-for="item in newsList"
          :href="`/articleDetail/${item.articleId}`"
          target="_blank"
          :key="item.articleId"
        >
          <span class="fl news-list-title single-row-ellipsis">{{ item.title }}</span>
          <span class="fr news-list-time">{{ handlerTime(item.date) }}</span>
        </a>
      </div>
    </div>
    <!-- 参赛指引 -->
    <div class="guide" ref="guideElem">
      <ul class="step-box flex-box wrap">
        <li class="step-bg" :class="{ finish: item.isInProgress }" v-for="(item, index) in gameGuide" :key="index">
          <div class="step-text"></div>
          <span class="step-time">{{ item.timeRange }}</span>
        </li>
      </ul>
      <div class="guide-btns flex-box">
        <a href="/match" target="_blank" class="detail-rule opacity9"></a>
        <a href="/upload" target="_blank" class="upload-works opacity9"></a>
      </div>
    </div>
    <!-- 参赛作品 -->
    <div class="works-list" ref="worksListElem">
      <div class="wrap relative">
        <WorksCard v-if="searchParams.cateId != -1" :params="searchParams" />
        <a :href="`/worksList?cateId=${searchParams.cateId}`" target="_blank" class="more opacity9 c000">查看更多</a>
      </div>
    </div>
    <!-- 合作媒体 -->
    <div class="media wrap">
      <!-- <span v-for="(item,index) in partnersData" :key="index"><img width="150" height="75" :src="item.img"  loading="lazy" alt=""></span> -->
    </div>
    <!-- 右侧上传作品按钮 -->
    <a href="/upload" target="_blank" class="upload-works-btn"></a>
    <!-- 左侧赛区导航 -->
    <div class="side-nav" ref="sideNavElem">
      <ul class="side-nav-box">
        <li
          v-for="item in worksTypeList"
          @click="searchParams.cateId = item.id"
          :key="item.id"
          :class="{ act: searchParams.cateId == item.id }"
          class="side-nav-item"
        >
          <span class="single-row-ellipsis">{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import HeaderLoop from '@/components/HeaderLoop.vue'
import WorksCard from '@/components/WorksCard.vue'
import { getGameTime, getWorksType, getMiniappUrlLink } from '@/service/home'
import { partnersData } from '@/assets/data/partners.js'
import { mapState } from 'vuex'

const gameGuideObj = { timeRange: '', isInProgress: false }
export default {
  name: 'HomeView',
  components: {
    HeaderLoop,
    WorksCard
  },
  data() {
    return {
      worksList: [],
      timer: null,
      gameGuide: Array(3)
        .fill(1)
        .map(() => ({ ...gameGuideObj })),
      worksTypeList: [],
      loopList: [],
      newsList: [],
      searchParams: {
        cateId: -1,
        pageSize: 9
      },
      partnersData
    }
  },
  computed: {
    convertGameTime() {
      return (time) => {
        let date = time.split(' ')[0]
        let timeStr = time.split(' ')[1]?.slice(0, 5)
        let index = time.indexOf('-') + (time.split('-')[1][0] == 0 ? 2 : 1)
        return time.substring(index, date.length).replace('-', '月') + '日' + (timeStr ? timeStr : '')
      }
    },
    handlerTime() {
      return (time) => {
        return time.split('-')[1] + '-' + time.split('-')[2]
      }
    },
    ...mapState('user', ['isLogin'])
  },
  created() {
    this.getGameTime()
    this.getWorksType()
    this.handleResponsive()
  },
  mounted() {
    this.scrollFn = this.scrollThrottle.bind(this)
    window.addEventListener('scroll', this.scrollFn)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollFn)
  },
  methods: {
    handleResponsive() {
      let ua = navigator.userAgent.toLowerCase()
      let isMobile = false
      let isWXWork = ua.match(/wxwork/i) == 'wxwork'
      let isWeixin = !isWXWork && ua.match(/micromessenger/i) == 'micromessenger'
      if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
        isMobile = true
      }
      if (isMobile) {
        if (!isWeixin) {
          window.location.href = 'weixin://dl/business/?appid=wxa8cf8ee722c4eec8&path=pages/home/index'
        } else {
          getMiniappUrlLink().then((res) => {
            if (+res.data.errcode === 0) {
              window.location.href = res.data.data.url
            }
          })
        }
      }
    },
    getOtherData(resData) {
      this.loopList = resData.rotateList
      this.newsList = resData.newsList
    },
    getGameTime() {
      getGameTime().then(({ data }) => {
        if (+data.errcode === 0) {
          const curTime = new Date().getTime()
          const { convertGameTime } = this
          const { recruitStartTime, recruitEndTime, contributeStartTime, contributeTime, awardsTime } = data.data
          const tempData = [recruitStartTime, recruitEndTime, contributeStartTime, contributeTime, awardsTime]
          this.gameGuide.map((item, index) => {
            if (index === this.gameGuide.length - 1) {
              item.timeRange = convertGameTime(tempData[2 * index])
            } else {
              item.timeRange = `${convertGameTime(tempData[2 * index])} - ${convertGameTime(tempData[2 * index + 1])}`
            }
            item.isInProgress = curTime - new Date(tempData[2 * index]).getTime() > 0 ? true : false
            return {
              timeRange: item.timeRange,
              isInProgress: true //item.isInProgress
            }
          })
        } else {
          console.log(data.errmsg)
        }
      })
    },
    getWorksType() {
      getWorksType().then(({ data }) => {
        if (+data.errcode === 0) {
          const resData = data.data
          this.worksTypeList = resData
          this.searchParams.cateId = +resData[0]?.id
          // 左侧赛事导航可视区内做监听
          this.observeView()
        } else {
          console.log(data.errmsg)
        }
      })
    },
    observeView() {
      const { sideNavElem, worksListElem } = this.$refs
      if (this.worksTypeList.length) {
        const intersectionObserver = new IntersectionObserver(
          (entries) => {
            let targeElem = entries[0].target
            if (entries[0].intersectionRatio >= 0.3) {
              if (sideNavElem && targeElem == worksListElem) {
                sideNavElem.classList.add('show')
              }
            } else {
              if (sideNavElem && targeElem == worksListElem) {
                sideNavElem.classList.remove('show')
              }
            }
          },
          {
            threshold: [0.3] // 展现面积为30%触发
          }
        )
        intersectionObserver.observe(worksListElem)
      } else {
        sideNavElem.classList.remove('show')
      }
    },
    scrollThrottle() {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          const uploadWorksBtn = document.querySelector('.upload-works-btn')
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          if (scrollTop > 800) {
            uploadWorksBtn.classList.add('show')
          } else {
            uploadWorksBtn.classList.remove('show')
          }
          this.timer = null
        }, 200)
      }
    }
  }
}
</script>
<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.news {
  margin: 34px auto 118px;
}
.news-carousel {
  width: 767px;
}
.news-carousel.ant-carousel >>> .slick-slide {
  height: 448px;
}
/deep/ .news-carousel.ant-carousel .slick-dots li.slick-active button {
  background: #6ddadf;
}
/deep/ .news-carousel.ant-carousel .slick-dots li button {
  width: 26px;
  height: 8px;
  border-radius: 4px;
  background: #a0a19c;
  margin: 0 3px;
}

/deep/ .news-carousel.ant-carousel .slick-dots-bottom {
  bottom: 70px;
}

.news-title-l {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 3%;
  height: 50px;
  line-height: 50px;
  background: #000;
  color: #fff;
  opacity: 0.8;
}
.news-box-l {
  position: relative;
  width: 768px;
  height: 448px;
}
.news-list-r {
  width: 433px;
  padding: 3px 40px 22px 29px;
  background: #7edde4;
  height: 448px;
}
.news-list-r .news-list-item {
  height: 70px;
  line-height: 70px;
  border-bottom: 1px dashed #92faff;
  display: block;
}
.news-list-r .news-list-item:hover .news-list-title {
  transform: scale(1.03);
}
.news-list-r .news-list-title {
  transition: all 0.3s;
  color: #fff;
  font-size: 18px;
  max-width: 320px;
}
.news-list-r .news-list-time {
  color: #1b767b;
  font-size: 12px;
}

.guide {
  padding: 218px 0 66px;
  background: url(https://www.c3online.com.cn/img/assets/images/guide.png) no-repeat center top,
    url(https://www.c3online.com.cn/img/assets/images/process-bar2.png) no-repeat center 422px;
  background-size: 1118px auto, 1376px auto;
}
.step-box {
  width: 1140px;
  justify-content: space-between;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 80px;
}
.step-box .step-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .step-box .step-bg{
  width: 200px;
  background: url(https://www.c3online.com.cn/img/assets/images/finish-bg.png) no-repeat;
  background-position: -200px 0;
} */
.step-box .step-bg.finish {
  background-position: 0 0;
}
.step-box .step-text {
  width: 200px;
  height: 126px;
}
.step-box .step-bg .step-text {
  background: url(https://icon.zol-img.com.cn/2023cj_pc_temp/wx/images/guideline-inactive.png) no-repeat;
}
.step-box .step-bg.finish .step-text {
  background: url(https://www.c3online.com.cn/img/assets/images/guideline-active.png) no-repeat;
}
.step-box .step-bg:nth-child(1) .step-text,
.step-box .step-bg.finish:nth-child(1) .step-text {
  background-position: 0 0;
}
.step-box .step-bg:nth-child(2) .step-text,
.step-box .step-bg.finish:nth-child(2) .step-text {
  background-position: -446px 0;
}
.step-box .step-bg:nth-child(3) .step-text,
.step-box .step-bg.finish:nth-child(3) .step-text {
  background-position: -917px 0;
}

.step-box .step-time {
  display: block;
  font-size: 24px;
  margin: 25px 0 28px;
  line-height: 1;
  position: relative;
  color: #000;
}
.step-box .step-time::before,
.step-box .step-time::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.step-box .step-time::before {
  width: 22px;
  height: 22px;
  background: url(@/assets/process-icon.png) no-repeat;
  background-size: 22px 22px;
  bottom: -42px;
}
.step-box .step-time::after {
  width: 16px;
  height: 16px;
  background: url(@/assets/notStartIcon.png) no-repeat left center;
  background-size: 16px 16px;
  bottom: -39px;
}

.step-box .finish .step-time::after {
  background: url(@/assets/startIcon.png) no-repeat -1px center;
  background-size: 16px 16px;
}
.guide-btns {
  width: 669px;
  margin: 54px auto 0;
  justify-content: space-between;
}
.detail-rule,
.upload-works {
  width: 313px;
  height: 82px;
  background: url(https://www.c3online.com.cn/img/assets/images/guide-btns.png) no-repeat;
  background-size: cover;
}
.upload-works {
  background-position: -355px 0;
}
.works-list {
  padding-top: 160px;
  background: url(https://www.c3online.com.cn/img/assets/images/works.png) no-repeat center top;
  background-size: 1218px 118px;
}

.more {
  display: block;
  width: 218px;
  height: 46px;
  line-height: 46px;
  background: #0fccd3;
  border-radius: 3px;
  text-align: center;
  margin: 32px auto 113px;
}

.side-nav {
  display: none;
  position: fixed;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 156px;
  height: auto;
  background: url(https://www.c3online.com.cn/img/assets/images/side-nav-bg.png) no-repeat 0px top;
  background-size: 137px auto;
}

.side-nav-box {
  padding: 8px 34px 1px 6px;
  border-right: 4px solid #0fccd3;
  margin-top: 107px;
}

.side-nav-box .side-nav-item {
  display: block;
  width: 124px;
  height: 50px;
  line-height: 50px;
  background: linear-gradient(90deg, #209cff, #68e0cf);
  margin-bottom: 20px;
  position: relative;
  color: #000;
}
.side-nav-box .side-nav-item span {
  display: block;
  width: 124px;
  padding: 0 10px;
}
.side-nav-box .side-nav-item::after {
  position: absolute;
  content: '';
  width: 15px;
  height: 16px;
  background: url(@/assets/hexagon.png) no-repeat;
  background-size: 15px 16px;
  top: 50%;
  margin-top: -8px;
  right: -31px;
}
.side-nav-box .side-nav-item.act,
.side-nav-box .side-nav-item:hover {
  background: linear-gradient(90deg, #c1fdb3, #68e0cf);
}

.media {
  /* width: 1214px; */
  height: 716px;
  background: url(https://icon.zol-img.com.cn/2023cj_pc_temp/wx/images/media.png) no-repeat center top;
  /* background-size: 1218px auto; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.media img {
  margin: 0 17px 24px;
}

.upload-works-btn {
  width: 118px;
  height: 118px;
  background: url(https://www.c3online.com.cn/img/assets/images/upload-works-btn.png) no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -59px;
  display: none;
}
</style>
