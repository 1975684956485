<template>
    <div class="wroks-card wrap clearfix">
        <div class="works-item cfff mr15 mb15" v-for="item in worksList" :key="item.docId">
            <!-- <router-link :to="{name:'worksDetail',query:{docId:item.docId}}" class="clearfix"> -->
            <a :href="`/worksDetail?docId=${item.docId}`" target="_blank">
                <img :src="item.pic" loading="lazy" width="358" height="265" alt="">
                <p class="works-title single-row-ellipsis c000 f18">{{ item.title }}</p>
            </a>
            <!-- </router-link> -->
            <div class="works-tags f16">
                <span class="works-tag" v-for="tag in item.tagInfo" :key="tag.id">{{ tag.name }}</span>
            </div>
            <div class="works-btm clearfix">
                <div class="fl">
                    <div class="works-author mb">
                        <img class="works-author-head" loading="lazy" width="29" height="29" :src="item.userInfo.headPicUrl" alt="">
                        <span class="works-author-name single-row-ellipsis ml8 c999 f16 text-left">作者：{{ item.nickName }}</span>
                    </div>
                    <div class="works-num f16 text-left">
                        当前票数：<span class="cur-num f22">{{item.allNum}}票</span>
                    </div>
                </div>
                <span class="vote-btn opacity9 cfff f24 fr" @click="worksVote(item.docId)">投票</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { getWorkList, getRecommendList, worksVote } from '@/service/home';
import { toQrLogin } from '@/utils/fn';

export default {
    name: 'WorksCard',
    props: {
        params: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            isVoting: false,
            worksList: [],
        }
    },
    computed: {
        ...mapState('user',['userInfo','isLogin'])
    },
    mounted() {
        const routeName = this.$route.name;
        if (routeName === 'home' || routeName === 'worksList') {
            this.getWorkList();
        }
        if (routeName === 'worksDetail') {
            this.getRecommendList();
        }
    },
    watch: {
        params: {
            handler() {
                const routeName = this.$route.name;
                if (routeName === 'home' || routeName === 'worksList') {
                    this.getWorkList();
                }
                if (routeName === 'worksDetail') {
                    this.getRecommendList();
                }
            },
            immediate: true,
            deep:true
        }
    },
    methods: {
        getWorkList() {
            const searchParams = Object.assign({ authorName: '', cateId: -1, order: 1, page: 1, pageSize: 9,tagId:0,sort:1 }, this.params)
            getWorkList(searchParams).then(({ data }) => {
                if (+data.errcode === 0) {
                    this.worksList = data.data.list;
                    this.$emit('totalNum', data.data.totalNumber);
                } else {
                    console.log(data.errmsg)
                }
            })
        },
        getRecommendList() {
            getRecommendList(this.params).then(({ data }) => {
                if (+data.errcode === 0) {
                    this.worksList = data.data;
                    this.$emit('worksLen', this.worksList.length);
                } else {
                    console.log(data.errmsg)
                }
            })
        },
        worksVote(docId) {
            if (this.isLogin) {
                if (this.isVoting) return;
                this.isVoting = true;
                worksVote({docId}).then(({ data }) => {
                    if (+data.errcode === 0) {
                        this.$message.success(data.errmsg);
                        this.worksList = this.worksList.map(item => {
                            if (item.docId == docId) {
                                item.allNum++;
                            }
                            return item;
                        })
                    } else {
                        this.$message.error(data.errmsg);
                    }
                    this.isVoting = false;
                })
            } else {
                toQrLogin();
            }
        }
    }
}
</script>
<style scoped>
.wroks-card{
    width: 1215px;
}
.works-item{
    width: 390px;
    padding: 20px 15px 35px;
    background: #fff;
    border-radius: 15px;
    float: left;
    box-shadow: 0 0 5px -1px #ddd;
}
.works-title{
    line-height: 1;
    margin: 20px 0 15px;
}
.works-tags{
    margin-bottom: 26px;
    height: 24px;
    overflow: hidden;
}
.works-tag{
    display: inline-block;
    line-height: 24px;
    padding: 0 10px;
    border-radius: 4px;
    margin: 0 3px;
    color: #fff;
}
.works-tags .works-tag:nth-child(5n+0){
    background: #75cbd8;
}
.works-tags .works-tag:nth-child(5n+1){
    background: #ff9cfe;
}
.works-tags .works-tag:nth-child(5n+2){
    background: #9ddbff;
}
.works-tags .works-tag:nth-child(5n+3){
    background: #ffd457;
}
.works-tags .works-tag:nth-child(5n+4){
    background: #a09bd5;
}
.works-author{
    display: flex;
    line-height: 29px;
}
.works-author-name {
    width: 160px;
}
.works-author-head{
    border-radius: 50%;
}
.cur-num{
    color: #00cdcb;
}
.works-num{
    line-height: 1;
    text-indent: 4px;
    color: #626262;
    max-width: 204px;
    overflow: hidden;
    white-space: nowrap;
}
.vote-btn{
    width: 152px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border-radius: 8px;
    background: #0fccd2;
    margin-top: 13px;
    cursor: pointer;
}
</style>