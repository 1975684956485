// 过滤内容中的html标签
export const noHtml = (t) => {
    t = t.replace(/({|})/g, '') // 过滤{}
    t = t.replace(/</g, '<') // 置换符号bai<
    t = t.replace(/>/g, '>') // 置换符号>
    t = t.replace(/<\/?[^>]*>/g, '') // *<\/?[^>]*>可以匹du配<script></style></body>等，并置空。而不是替换<和zhi>两个符号
    t = t.replace(/\s/g, '') // 过滤空格
    return t
  }
  
//用word方式计算正文字数
export const wordCount = (str) => {
    // 中文算2个长度，最后再除以二
    // return str ? Math.ceil(str.replace(/[^\x20-\xff]/g, 'mm').length / 2) : ''
  
    // 用word方式计算正文字数
    // let sLen = 0;
    // try{
    //     //先将回车换行符做特殊处理
    //     str = str.replace(/(\r\n+|\s+| +)/g,"龘");
    //     //处理英文字符数字，连续字母、数字、英文符号视为一个单词
    //     // eslint-disable-next-line 
    //     str = str.replace(/[\x00-\xff]/g,"m");
    //     //合并字符m，连续字母、数字、英文符号视为一个单词
    //     str = str.replace(/m+/g,"*");
    //     //去掉回车换行符
    //     str = str.replace(/龘+/g,"");
    //     //返回字数
    //     sLen = str.length;
    // }catch(e){
    //   console.log(e)
    // }
    // return sLen;
    
    let count = 0;
    if (!str) return count;
    for (var i = 0; i < str.length; i++)
        if (str.charCodeAt(i) >= 10000 || str == "·") {
            count = count + 2;
        } else {
            count = count + 1;
        }
    return count/2;
    
    // var len = 0;
    // for (var i = 0; i < str.length; i++) {
    //   var a = str.charAt(i);
    //   if (a.match(/[^\x00-\xff]/ig) != null){
    //     len += 2;
    //   }else{
    //     len += 1;
    //   }
    // }
    // return len;
}
  
// 跳转到二维码登录
export const toQrLogin = ()=> {
    window.location.href= `//www.c3online.com.cn/api/v1/wechat.auth.web.loginQr?backurl=${encodeURIComponent(window.location.href)}`;
}