import { requestData } from '@/utils/request';

export const login = (data) => {
    return requestData({
      type:'POST',
      url: `/v1/wechat.auth.web.loginPhone`,
      data,
    })
}

export const logout = (data) => {
    return requestData({
      url: `/logout`,
      data,
    })
}

export const getCaptcha = (data) => {
    return requestData({
      url: `/send.phone.code`,
      data,
    })
}

export const getUserInfo = (data) => {
    return requestData({
      url: `/v1/user.info.get`,
      data,
    })
}

