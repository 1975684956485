import { notification } from 'ant-design-vue'
import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'
import { extend } from 'lodash'


axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_OPEN_API_HOST
// 创建 axios 实例

const request = axios.create({
  // baseURL: process.env.VUE_APPA_APIA_BASE_URL,
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000
})

const errorHandler = error => {
  if (error.response) {
    const data = error.response.data

    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.errmsg
      })
    }

    if (
      error.response.status === 401 &&
      !(data.request && data.request.isLogin)
    ) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
    }
  }
  return Promise.reject(error)
}

//requst interceptor
request.interceptors.request.use(config => {
    let token = localStorage.getItem('token');
    if (token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.authorization = `token ${token}`;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
)

//response interceptor
request.interceptors.response.use(response => {
  // 未登录，后段自定义状态码
  if (response.data.errcode === 10004) {
    console.log('走了这里')
    // window.location.href = '/user/login'
  } else {
    return response.data
  }
}, errorHandler)

const installer = {
    vm: {},
    install(Vue) {
        // Vue.use(request)
    }
}
async function requestData(options) {
  const { data = {}, type = 'get', url = '', dataType, callbackParamName, ...AxiosRequestConfig } = options

  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })

  switch (type?.toLocaleLowerCase()) {
    case 'post':
      return axios.post(
        url,
        formData,
        extend(
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
          },
          AxiosRequestConfig
        )
      )
    default:
      return axios.get(
        url,
        extend(
          {
            params: data,
            ...AxiosRequestConfig,
          },
          dataType === 'jsonp' ? { adapter: jsonpAdapter, callbackParamName } : {}
        )
      )
  }
}
const upload = (url, data, fileType = 'document') => {
  return requestData({
    url,
    type: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: fileType === 'document' ? 180000 : 10000,
  })
}


export default request

export {
    installer as VueAxios,
    request as axios,
    requestData,
    upload
}
